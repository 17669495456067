<template>
    <div class="head-carousel">
        <carousel :items-to-show="1.0">
            <slide v-for="slide in dataSlide" :key="slide">
                <img :src="BaseIMG + '/assets/img/slider/' + slide.image" :alt="slide.alt" class="img-responsive" style="width: 100%;" />
            </slide>
            <template #addons>
            <navigation />
            <pagination />
            </template>
        </carousel>
    </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import { getSlider } from '@/api.js';
    export default {
        components: {
            Carousel,
            Slide,
            Pagination,
            Navigation,
        },
        data() {
            return {
                BaseIMG: process.env.VUE_APP_IMG,
                dataSlide : []
            }
        },
        methods: {
          async fetchSlide() {
            try {
              const response = await getSlider();
              this.dataSlide = response.data.data;
            } catch (error) {
              console.error('Error fetching slide data:', error);
            }
          },
        },
        created() {
        this.fetchSlide();
      },
    }
</script>

<style>
.carousel-item {
  width: 100%;
}
.head-carousel {
  padding-top: 3rem;
}
</style>
