<template>
    <div>
        
        <div class="container">
            <div class="section-title">
            <h2> Product Gallery</h2>
            <p>There are some photos of our latest products..</p>
            </div>
                <swiper
                :modules="modules"
                :slides-per-view="3"
                :space-between="50"
                navigation
                :pagination="{ clickable: true }"
                :scrollbar="{ draggable: true }"
                :autoplay="{ delay: 3500 }"
                @swiper="onSwiper"
                @slideChange="onSlideChange" 
                >
                    <swiper-slide v-for="row in dataList" :key="row.id">
                        <a href="#"><img :src="BaseIMG + '/assets/img/product/' + row.image" class="img-fluid" alt=""/></a>
                    </swiper-slide>
                </swiper>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Parallax, Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { getSwiper } from '@/api.js';

  import 'swiper/css';
  import 'swiper/css/navigation';
  import 'swiper/css/pagination';
  import 'swiper/css/scrollbar';
    export default {
        components: {
            Swiper,
            SwiperSlide,
        },
        data() {
            return {
              BaseIMG: process.env.VUE_APP_IMG,
                dataList: []
            }
        },
        methods: {
          async fetchSwiper() {
            try {
              const response = await getSwiper();
              this.dataList = response.data.data;
            } catch (error) {
              console.error('Error fetching slide data:', error);
            }
          },
        },
        created() {
            this.fetchSwiper();
        },
        setup() {
        const onSwiper = (swiper) => {
            // console.log(swiper);
        };
        const onSlideChange = () => {
            // console.log('slide change');
        };
        return {
            onSwiper,
            onSlideChange,
            modules: [ Parallax,  Navigation, Pagination, Scrollbar, A11y, Autoplay],
        };
      },
    }
</script>

<style lang="scss" scoped>

</style>