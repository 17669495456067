<template>
    <div>
        <topbar-nav></topbar-nav>
        <header-nav></header-nav>
        <section id="services" class="services services head-product">
        <div class="container">
            <div class="section-title">
            <h2>Products</h2>
            <p> List Data Product Biotech.</p>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 icon-box" v-for="item in dataProduct" :key="item.id">
                    <a :href="'product/detail/'+ item.code">
                        <div class="img-product">
                            <img :src="BaseIMG + '/assets/img/product/' + item.image" class="img-fluid" alt=""/>
                        </div>
                        <h4 class="title"><a href="">{{ item.nama }}</a></h4>
                    </a>
                </div>
            </div>
            <div v-if="isLoading">Loading...</div>
        </div>
        </section>
        <footer-nav></footer-nav>
    </div>
</template>

<script>
import TopbarNav from '@/components/Topbar'
import HeaderNav from '@/components/Header'
import FooterNav from '@/components/Footer'
import { getProductId } from '@/api.js';
    export default {
        components: {
            TopbarNav, HeaderNav, FooterNav
        },
        data() {
            return {
                BaseIMG: process.env.VUE_APP_IMG,
                dataProduct: [],
                current_page: 1,
                per_page: 10,
                isLoading: false
            }
        },
        mounted() {
            this.fetchProduct();
            window.addEventListener('scroll', this.handleScroll);
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.handleScroll);
        },
        methods: {
          async fetchProduct() {
            try {
                this.isLoading = true;
                this.id = this.$route.params.id;
              const response = await getProductId(this.id, this.current_page, this.per_page);
              this.dataProduct = this.dataProduct.concat(response.data.data.data);
              this.current_page++;
              this.isLoading = false;
            } catch (error) {
              console.error('Error fetching Product data:', error);
            }
          },
        },
        handleScroll() {
            if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
                this.fetchProduct();
                }
            },
    }
</script>

<style>
.head-product {
    padding-top: 9rem;
}
.img-product {
    margin-bottom: 16px;
    border: solid 2px #ccc;
    border-radius: 17px;
}
</style>