<template>
    <div>
        <topbar-nav></topbar-nav>
        <header-nav></header-nav>
        <section id="services" class="services services head-product">
        <div class="container">
            <div class="section-title">
            <h2>Products</h2>
            <p>Magnam dolores List Data Product Biotech.</p>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 icon-box">
                    <div class="img-product">
                        <img :src="BaseIMG + '/assets/img/product/' + image" class="img-fluid" alt=""/>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 product">
                    <h3>SPESIFIKASI DATA PRODUCT</h3>
                    <div class="form-group py-2">
                        <label for="">Name Product :</label>
                        &nbsp;&nbsp; <b>{{ nama }}</b>
                    </div>
                    <div class="form-group py-2">
                        <label for="">Category Product :</label>
                        &nbsp;&nbsp; <b>{{ category }}</b>
                    </div>
                    <div class="product-detail p-3">
                        <p>{{ message }}</p>
                    </div>
                </div>
            </div>
        </div>
        </section>
        <footer-nav></footer-nav>
    </div>
</template>

<script>
import TopbarNav from '@/components/Topbar'
import HeaderNav from '@/components/Header'
import FooterNav from '@/components/Footer'
import { getProductDetail } from '@/api.js';
    export default {
        components: {
            TopbarNav, HeaderNav, FooterNav
        },
        data() {
            return {
                BaseIMG: process.env.VUE_APP_IMG,
                image: '',
                nama: '',
                category: '',
                message: ''
            }
        },
        methods: {
          async fetchProduct() {
            try {
              const response = await getProductDetail(this.$route.params.code);
              this.image = response.data.data.image;
              this.nama = response.data.data.nama;
              this.category = response.data.data.categories.nama;
              this.message = response.data.data.message;
            } catch (error) {
              console.error('Error fetching Product data:', error);
            }
          },
        },
        created() {
        this.fetchProduct();
      },
    }
</script>

<style>
.head-product {
    padding-top: 9rem;
}
.img-product {
    margin-bottom: 16px;
    border: solid 2px #ccc
}
.product {
    padding: 1rem 3rem;
}
.product h3 {
    text-align: center;
    margin-bottom: 1rem;
}
.product-detail {
    border: solid 1px #333;
    border-radius: 15px;
    margin-top: 2rem;
}
</style>